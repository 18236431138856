<template>
  <div id="referrals">
    <v-treeview :items="users" item-children="referrals" item-text="">
      <template v-slot:prepend="{ item }">
        <v-icon v-text="`mdi-account-network-outline`"></v-icon>
        <span :class="getItemClass(item)">{{item.email}}</span>
      </template>
    </v-treeview>
  </div>
</template>
<script>
import { getReferrals } from "@/services/users";
import moment from "moment";

export default {
  name: "referrals",
  data() {
    return {
      users: [],
    };
  },
  methods: {
    getItemClass(item) {
      const expiresAt = moment(item.expiresAt).format('YYYY-MM-DD')
      const todaysDate = moment().format('YYYY-MM-DD')
      return moment(expiresAt).isAfter(todaysDate) ? 'text-green' : 'text-black'
    }
  },
  beforeMount() {
    let self = this;
    getReferrals(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        self.users = data.payload.users;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
